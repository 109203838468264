import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import TableOfContents from '../components/table-of-contents';
import Breadcrumbs from "../components/breadcrumbs"
import Thumbnail from "../components/thumbnail";
import resolveUrl from "../utils/urls/resolve-url";
import Layout from "../components/layout";
import Icon from "../components/icon.tsx";
import Translate from "../components/translate";
import CommentsForm from "../components/comments-form";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
)

const Vindicators = ({
  data: { allMysqlBlog, mysqlTerms, faq },
  pageContext: { breadcrumbs },
  location: { pathname }
}) => {

  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  return (
    <Layout pathname={pathname}>
      <div className={[b.container, b.pt3, 'breadcrumbs-mobile', b.ptLg5, "scroll-inner"].join(" ")}>
        <Breadcrumbs theme='white' items={breadcrumbs} />
      </div>
      <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
        <div className={["blueToGray", b.mb4].join(" ")}>
          <h1 className={["blackToWhite", 'big-title', b.mb2].join(" ")}>{mysqlTerms.title}</h1>
          <div className='blackToWhite' dangerouslySetInnerHTML={{ __html: mysqlTerms.description }} />
        </div>
        <div className={[b.row].join(' ')}>
          {allMysqlBlog.nodes.map(({ title, published_date, description, image_description, term_normalized, terms_path, url, mysqlImages }, i) => (
            <div className={[b.colLg3, b.colMd6, b.my3].join(' ')} key={i} >
              <div className={['bank-list-products-box', b.h100].join(' ')}>
                <div className={['debtors-box','dark-mode-image'].join(' ')}>
                  <Thumbnail image={mysqlImages[0]} alt={image_description || ''} />
                </div>
                <div className="post-body">
                  <div>
                    <span className={["post-text", 'font-size-10', 'post-text-color'].join(' ')}>{published_date}</span>
                    <div className={[b.h6, 'font-size-18', b.mb3, b.mt1].join(' ')}>
                      <Link to={resolveUrl(terms_path, term_normalized, url)} className={["post-link", "post-heading-color"].join(' ')}>{title}</Link>
                    </div>
                    <p className={['post-text', 'post-text-color'].join(' ')}>
                      {description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {(mysqlTerms.description_additional || mysqlTerms.description_alt) && <div className={`${b.row} product-page blog-content`}>
          <div className={[b.colXl4, b.mb3, b.orderXlLast].join(' ')}>
            <TableOfContents selector=".post-content h2[id]" />
          </div>
          
          <section className={`${b.colXl8} blackToGray dark-theme-listing`}>
            {<BlogContent content={`${mysqlTerms.description_alt} ${mysqlTerms.description_additional}`} className={b.pt0} />}
            {faq?.nodes.length > 0 && (
              <>
                <h2 id="section-title-15">
                  Często zadawane pytania
                </h2>
                {faq.nodes.map(({ title, text }, i) => (
                  <Faq title={title} text={text} key={i}/>
                ))}
                <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                  <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm();toggleFaq(true); }}>
                    <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                    <Translate tKey="Ask your own question" />
                  </button>
                </div>
              </>
            )}
          </section>
          <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlTerms.mysqlId} commentType="term" faq={isFaq} />
        </div>}
      </div>

    </Layout>
  )
}

export default Vindicators

export const query = graphql`
query DeferredPaymentsQuery($mysqlId: Int!) {
  allMysqlBlog(filter: {term_id: {eq: $mysqlId}}) {
    nodes {
      mysqlImages {
        childImageSharp {
          gatsbyImageData(width: 295)
        }
      }
      title
      published_date
      description
      image_description
      term_normalized
      terms_path
      url
    }
  }
  mysqlTerms(mysqlId: {eq: $mysqlId}) {
    description
    title
    description_alt
    description_additional
  }
  faq: allMysqlFaq(
    filter: {type: {eq: "term"}, item: {eq: $mysqlId}, answer: {ne: "null"}}
    sort: {fields: priority, order: DESC}
  ) {
    nodes {
     title: question
     text: answer
    }
  }
  allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
}
`